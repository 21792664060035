import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagService } from '../services/feature-flag.service';

@Directive({ selector: '[hbFeatureFlag]', standalone: true })
export class FeatureFlagDirective {
  constructor(
    private featureFlagService: FeatureFlagService,
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>
  ) { }

  @Input('hbFeatureFlag') public set featureFlag(feature: boolean) {
    if (this.featureFlagService.getFeature() !== feature) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
